import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"

const Header = ({ siteTitle }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title> {siteTitle} </title>
      <meta name="description" content="Regional interactive data stories show cross-sector data that describe general characteristics of each of five DMHAS regions." />
      <meta name="author" content="CTData Collaborative" />
      <link rel="shortcut icon" type="image/x-icon" href={favicon} />
    </Helmet>

    <header className="pv2 ph3 helvetica mw9 center" style={{backgroundColor: '#100154'}} >

      <Link to="/" className="link white dim">
        <h3 className="f3 ma0 dib normal">
          {siteTitle}
        </h3>
      </Link>

    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
