/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Dmhas from "../images/dmhas.jpg"
import Cpes from "../images/cpes.png"
import Ctdata from "../images/ctdata.png"

// This is needed for data labels to appear
/* eslint-disable */
import ChartDataLabels from 'chartjs-plugin-datalabels'
/* eslint-enable */

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className="helvetica mw9 center ph3 mt4">{children}</main>

      <footer className="mt4 pv3 ph3 bg-near-white helvetica mw9 center lh-copy cf">
        
        <div className="w-100 w-two-thirds-l fl cf black-70">

          <h4 className="f4 mb1">About</h4>

          <p className="measure-wide">
            The regional data stories are abridged samples, representative of the
            format and functionality of the expanded stories to come.
            Visit us soon for additional content, new data, and visualizations! 
          </p>

          <p className="measure-wide f5 mb4 mt0">
            <b>DMHAS Regional Data Stories</b> are created
            for CT Department of Mental Health and Addiction Services
            by CTData Collaborative.
          </p>

          <img src={Cpes} className="db fl mr3 h3" alt="CPES Logo" />
          <img src={Dmhas} className="db fl mr3 h3" alt="DMHAS Logo" />
          <img src={Ctdata} className="db fl h3" alt="CTData Logo" />

          <div className="cf"></div>

          <p className="measure-wide f6 black-50 mt4">
            Maps are made with <a className="link black-50 dim" href="https://leafletjs.com/">Leaflet</a> and
            CartoDB baselayers (&copy; {' '}
              <a className="link black-50 dim" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors
              &copy; CartoDB, CartoDB <a href="https://carto.com/attribution" className="link black-50 dim">attributions</a>)
          </p>

        </div>

        <div className="w-100 w-third-l fl cf black-70">
          <h4 className="f4 mb1">Links</h4>
          <a href="https://preventionportal.ctdata.org/" className="link black-70 dim dib mb1">SEOW Prevention Data Portal</a><br/>
          <a href="https://ctdata.org" className="link black-70 dim dib mb1">CTData Collaborative</a><br/>
          <a href="https://health.uconn.edu/public-health-sciences/programs/health-services-research-unit/center-for-prevention-evaluation-and-statistics-cpes/" className="link black-70 dim dib mb1">The DMHAS Center at UConn Health</a><br/>
          <a href="https://portal.ct.gov/dmhas" className="link black-70 dim">CT Department of Mental Health and Addiction Services</a><br/>
        </div>
      </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
